body {
    margin: 0;
    font-family: Times New Roman;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: Times New Roman;
  }
  