.three-js-preview {
  width: 100%;
  height: 400px;
  position: relative;
}


.preview-title {
  margin-top: 0;
  color: #333;
  font-size: 1.4em;
  margin-bottom: 15px;
}

.preview-iframe {
  width: 100%;
  height: 100%;
  border: none;
}


.code-description {
  background-color: #e8f5e9;
  padding: 15px;
  border-radius: 4px;
}

.description-title {
  margin-top: 0;
  color: #2e7d32;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.description-steps {
  padding-left: 20px;
  margin-bottom: 15px;
}

.description-steps li {
  margin-bottom: 8px;
  color: #1b5e20;
  line-height: 1.4;
}

.execution-instruction {
  font-style: italic;
  color: #555;
  margin-bottom: 0;
}

.error-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 10px;
  font-family: Times New Roman;
}


.error-message button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.error-message button:hover {
  background-color: #b71c1c;
}
