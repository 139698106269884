.marketplace {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .agent-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .agent-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;
  }
  
  .agent-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .agent-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .agent-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .agent-type {
    font-style: italic;
    color: #666;
  }
  
  .agent-description {
    flex-grow: 1;
    margin-bottom: 10px;
  }
  
  .agent-created {
    font-size: 0.9em;
    color: #555;
  }
  
  .agent-actions {
    margin-top: 10px;
  }
  
  .Market-select-btn, .Market-deselect-btn {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Market-select-btn {
    background: linear-gradient(45deg, #7DFF9B 0%, #fdff71 100%);
    border-radius: 20px;
    color: #222222;
  }
  
  .Market-select-btn:hover {
    background-color: #45a049;
  }
  
  .Market-deselect-btn {
    background-color: #f44336;
    border-radius: 20px;
    color: white;
  }
  
  .Market-deselect-btn:hover {
    background-color: #d32f2f;
  }
  