.chat-container {
  display: flex;
  height: calc(100vh - 100px);
  overflow: hidden;
  background-image: url(../image/chatmain.png);
  background-size: 100% 100%;
  padding: 25px;
  .split-line {
    height: calc(100vh - 100px);
    width: 1px;
    background-color: #e0e0e0;
  }
}


.disabled-link {
  pointer-events: none;
}

.sidebar {
  width: 300px;
  overflow-y: auto;
  padding-right: 20px;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.logo {
  margin: 10px 0 25px 0;
  display: flex;
  justify-content: center;
  img {
    width: 142px;
    height: 37px;
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #2E8B57;
  margin: 25px 0;
}

.title img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.chat-agent-item {
  background: #E7FFED;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #B8EFC5;
  border-left: 5px solid transparent;
  transition: all 0.3s ease;
}

.chat-agent-item.selected {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-agent-item h4 {
  margin: 0 0 10px 0;
}

.chat-agent-item p {
  margin: 0;
  font-size: 14px;
  color: #41D263;
}

.chat-agent-item button {
  width: 90px;
  margin-top: 10px;
  padding: 5px 10px;
  background: #41D263;
  color: white;
  border: none;
  border-radius: 19px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-agent-item button.selected {
  background-color: #45a049;
}

.agent-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-to {
  width: 290px;
  height: 30px;
  margin: auto;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #33DD33;
  font-size: 16px;
  color: #33DD33;
  border-radius: 15px;
  &:hover {
    background: #33DD33;
    color: #fff;
  }
}

.See-All {
  width: 290px;
  height: 30px;
  border: 1px solid #33DD33;
  margin: auto;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  font-size: 16px;
  color: #33DD33;
  border-radius: 15px;
  &:hover {
    background: #33DD33;
    color: #fff;
  }
}

.marketplacebutton {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7DFF9B;
  border-radius: 35px;
  border: 2px solid #F1FFFD;
  font-weight: bold;
  font-size: 26px;
  color: #2E8B57;
  margin: 25px 0 15px 0;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.Marketplace-agent {
  position: relative;
  height: 36px;
  background-image: url(../image/Marketplaceagent.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.Marketplace-agent img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
}

.selectedAgent {
  margin-left: 20px;
  color: #222;
  span {
    color: #02ED39;
  }
}

.selected-Team {
  margin-left: 20px;
  color: #222;
  span {
    color: #02ED39;
  }
}

.Tips {
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  color: #888888;
}

.credit-info {
  background: #E2FFD6;
  border-radius: 10px;
  padding: 6px 8px;
  margin-right: 12px;
  font-size: 14px;
  color: #3da13d;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}

.send-button {
  background-image: url(../image/send.png);
  background-size: 100% 100%;
  background: #DDFF53;
  border-radius: 12px;
}

.clear-button {
  height: 35px;
  background: #DDFF53;
  border-radius: 12px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}

.agent-description {
  margin-bottom: 18px;
  display: flex;
  color: #898888;
  img {
    width: 11px;
    height: 12px;
    margin-right: 9px;
  }
  p {
    margin: 0;
  }
}

.agent-owner {
  color: #898888;
  border-radius: 10px;
  border: 1px dashed #888888;
  padding: 10px;
  div {
    margin-top: 10px;
  }
}

.buy-Credits {
  position: absolute;
  top: -18px;
  left: 15px;
  padding: 10px;
  color: #3da13d;
  background: #E2FFD6;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
}

.Purchase-credits-modal {
  .ant-btn {
    display: none;
  }
}
.Purchase-table {
  padding: 5px;
  font-size: 12px;
  text-align: center;
  div {
    border: 1px solid #e0e0e0;
  }
  .table-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #e0e0e0; */
    border: none;
  }
  .table-tab {
    font-weight: bold;
  }
  .table-content {
    div {
      height: 30px;
      line-height: 30px;
    }
    input {
      width: 50px;
    }
    /* margin: 10px 0; */
    margin-top: -2px;
    .buy-button {
      width: 70%;
      height: 22px;
      line-height: 22px;
      background: #E2FFD6;
      border-radius: 19px;
      color: #3da13d;
      cursor: pointer;
    }
  }
}