.team-management {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-image: url(../image/chatmain.png);
  background-size: 100% 100%;
  overflow: hidden;
  .team-contex {
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

.Hide {
  width: 30px;
  height: 30px;
  background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
  }
}

.team-management h3 {
  color: #222;
  border-bottom: 2px solid #F6E918;
  padding-bottom: 10px;
}

/* 按钮样式 */
 .create-team-btn, .use-team-btn {
  text-decoration: none;
  background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
  color: #222;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px 0;
  transition: background-color 0.3s;
  font-family: Times New Roman;
}

.toggle-form-btn:hover, .create-team-btn:hover, .use-team-btn:hover {
  background-color: #45a049;
}

/* 创建和编辑团队表单 */
.create-team-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  .title {
    font-size: 16px;
    color: #222;
    margin: 15px 0;
  }
}

.create-team-form h3, .edit-team-form h3 {
  margin-top: 0;
  color: #222;
}

.create-team-form input, .create-team-form textarea {
  width: 98%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-size: 14px;
  background-color: #ECECEC;
  font-family: Times New Roman;
}

/* 代理选择区域 */
.agent-selection {
  margin-top: 20px;
  .agent-item {
    margin-bottom: 8px;
    .border {
      width: 15px;
      height: 4px;
      background: #888888;
      border-radius: 2px;
      margin-right: 5px;
    }
    .Select {
      width: 510px;
      height: 30px;
      background: #ECECEC;
      border-radius: 15px;
      padding: 5px 10px;
      box-sizing: border-box;
      margin: 10px 0 20px 20px;
      select {
        width: 100%;
        color: #000;
        background: none;
        border: none;
        outline: none;
        font-family: Times New Roman;
      }
    }
  }
  button {
    background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
    color: #222;
    border-radius: 20px;
    border: none;
    width: 80px;
    height: 30px;
    margin: 10px 0 20px 10px;
    font-family: Times New Roman;
  }
}

.agent-selection h4 {
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.agent-lists {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.user-agents, .marketplace-agents {
  width: 48%;
}

.user-agents h5, .marketplace-agents h5 {
  margin-bottom: 10px;
}

.agent-item:hover {
  background-color: #f0f0f0;
}

.agent-item.selected {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

.agent-item span {
  font-weight: bold;
}

/* 团队列表 */
.team-list {
  margin-top: 30px;
}

.team-item {
  background: #FFFFFF;
  border-radius: 25px;
  border: 1px solid #222222;
  padding: 15px;
  margin: 10px 0;
  transition: box-shadow 0.3s;
}

.team-item:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}

.team-item h4 {
  margin-top: 0;
  color: #4CAF50;
}

.team-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.team-actions button, .team-actions .use-team-btn {
  margin-right: 10px;
}

/* 编辑团队操作 */
.edit-team-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.edit-team-actions button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-team-actions button:first-child {
  background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
  color: #222;
  border-radius: 20px;
  margin: 10px 30px 10px 0;
  width: 150px;
  height: 38px;
  font-family: Times New Roman;
}

.edit-team-actions button:first-child:hover {
  background-color: #45a049;
}

.edit-team-actions button:last-child {
  background-color: #f44336;
  color: white;
}

.edit-team-actions button:last-child:hover {
  background-color: #d32f2f;
}

.Edit {
  width: 100px;
  border: none;
  color: #222;
  background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
  border-radius: 20px;
  margin: 10px 0;
  height: 38px;
  font-family: Times New Roman;
}

.edit-team-form {
  background: #FFFFFF;
  border-radius: 25px;
  border: 1px solid #222222;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.edit-team-form input, .edit-team-form textarea {
  width: 86%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  font-size: 14px;
  background: #ECECEC;
  border-radius: 20px;
  font-family: Times New Roman;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .agent-lists {
    flex-direction: column;
  }

  .user-agents, .marketplace-agents {
    width: 100%;
  }

  .team-actions {
    flex-direction: column;
  }

  .team-actions button, .team-actions .use-team-btn {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}

.buy-slot-btn, .toggle-form-btn{
  background: linear-gradient(45deg, #7DFF9C 0%, #E7FF85 100%);
  color: #222;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px 0;
  transition: background-color 0.3s;
  margin-right: 10px;
  font-family: Times New Roman;
}

.team-actions .Delete, .edit-team-actions button:last-child, .Cancel {
  background: linear-gradient(45deg, #FF4747 0%, #FF9138 100%);
  border: none;
  color: white;
  border-radius: 20px;
  margin: 10px 0;
  width: 100px;
  height: 38px;
  font-family: Times New Roman;
}